@font-face {
  font-family: "ABBLibrary - ABBvoice";
  src: url(./fonts/ABBvoice_W_Rg.woff2) format("woff2"),
    url(./fonts/ABBvoice_W_Rg.woff) format("woff"),
    url(./fonts/ABBvoice_W_Rg.eot) format("eot");
  unicode-range: U+0000-007F, U+00A0-024F, U+1E00-1EFF, U+0370-03FF, U+1F00-1FFF,
    U+0400-052F;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "ABBLibrary - ABBvoice";
  src: url(./fonts/ABBvoice_W_Bd.woff2) format("woff2"),
    url(./fonts/ABBvoice_W_Bd.woff) format("woff"),
    url(./fonts/ABBvoice_W_Bd.eot) format("eot");
  unicode-range: U+0000-007F, U+00A0-024F, U+1E00-1EFF, U+0370-03FF, U+1F00-1FFF,
    U+0400-052F;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "ABBLibrary - ABBvoice";
  src: url(./fonts/ABBvoice_W_Md.woff2) format("woff2"),
    url(./fonts/ABBvoice_W_Md.woff) format("woff"),
    url(./fonts/ABBvoice_W_Md.eot) format("eot");
  unicode-range: U+0000-007F, U+00A0-024F, U+1E00-1EFF, U+0370-03FF, U+1F00-1FFF,
    U+0400-052F;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "ABBLibrary - ABBvoice";
  src: url(./fonts/ABBvoice_W_Lt.woff2) format("woff2"),
    url(./fonts/ABBvoice_W_Lt.woff) format("woff"),
    url(./fonts/ABBvoice_W_Lt.eot) format("eot");
  unicode-range: U+0000-007F, U+00A0-024F, U+1E00-1EFF, U+0370-03FF, U+1F00-1FFF,
    U+0400-052F;
  font-weight: 200;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "ABBLibrary - ABBvoice";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --focus-border: #dcdcdc !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d2d2d2;
  border-radius: 10px;
  margin: 12px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a9a9a9;
  border-radius: 10px;
  width: 120px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6e6e6e;
}

.MuiAutocomplete-listbox::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.wide-scrollbar::-webkit-scrollbar {
  width: 9px !important;
}

.wide-scrollbar-horizontal::-webkit-scrollbar {
  height: 9px !important;
}

/*reflex styles*/

body.reflex-col-resize {
  cursor: col-resize;
}

body.reflex-row-resize {
  cursor: row-resize;
}

.reflex-container {
  justify-content: flex-start; /* align items in Main Axis */
  align-items: stretch; /* align items in Cross Axis */
  align-content: stretch;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex;
  position: relative;

  height: 100%;
  width: 100%;
}

.reflex-container.horizontal {
  flex-direction: column;
  min-height: 1px;
}

.reflex-container.vertical {
  flex-direction: row;
  min-width: 1px;
}

.reflex-container > .reflex-element {
  position: relative;
  overflow: auto;
  height: 100%;
  width: 100%;
}

.reflex-container.reflex-resizing > .reflex-element {
  pointer-events: none;
  user-select: none;
}

.reflex-container > .reflex-element > .reflex-size-aware {
  height: 100%;
  width: 100%;
}

.reflex-container > .reflex-splitter {
  background-color: #eeeeee;
  z-index: 100;
}

.reflex-container > .reflex-splitter.active,
.reflex-container > .reflex-splitter:hover {
  background-color: #c6c6c6;
  transition: all 1s ease;
}

.horizontal > .reflex-splitter {
  border-bottom: 1px solid #c6c6c6;
  border-top: 1px solid #c6c6c6;
  cursor: row-resize;
  width: 100%;
  height: 2px;
}

.reflex-element.horizontal .reflex-handle {
  cursor: row-resize;
  user-select: none;
}

.reflex-container.horizontal > .reflex-splitter:hover,
.reflex-container.horizontal > .reflex-splitter.active {
  border-bottom: 1px solid #eeeeee;
  border-top: 1px solid #eeeeee;
}

.reflex-container.vertical > .reflex-splitter {
  border-right: 1px solid #c6c6c6;
  border-left: 1px solid #c6c6c6;
  cursor: col-resize;
  height: 100%;
  width: 2px;
}

.reflex-element.vertical .reflex-handle {
  cursor: col-resize;
  user-select: none;
}

.reflex-container.vertical > .reflex-splitter:hover,
.reflex-container.vertical > .reflex-splitter.active {
  border-right: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
}

.reflex-container > .reflex-splitter.reflex-thin {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
  opacity: 0.2;
  z-index: 100;
}

.reflex-container
  > .reflex-splitter.reflex-thin.active
  .reflex-container
  > .reflex-splitter.reflex-thin:hover {
  transition: all 1.5s ease;
  opacity: 0.5;
}

.reflex-container.horizontal > .reflex-splitter.reflex-thin {
  border-bottom: 8px solid rgba(255, 255, 255, 0);
  border-top: 8px solid rgba(255, 255, 255, 0);
  height: 17px !important;
  cursor: row-resize;
  margin: -8px 0;
  width: 100%;
}

.reflex-container.horizontal > .reflex-splitter.reflex-thin.active,
.reflex-container.horizontal > .reflex-splitter.reflex-thin:hover {
  border-bottom: 8px solid rgba(228, 228, 228, 1);
  border-top: 8px solid rgba(228, 228, 228, 1);
}

.reflex-container.vertical > .reflex-splitter.reflex-thin {
  border-right: 8px solid rgba(255, 255, 255, 0);
  border-left: 8px solid rgba(255, 255, 255, 0);
  width: 17px !important;
  cursor: col-resize;
  margin: 0 -8px;
  height: 100%;
}

.reflex-container.vertical > .reflex-splitter.reflex-thin.active,
.reflex-container.vertical > .reflex-splitter.reflex-thin:hover {
  border-right: 8px solid rgba(228, 228, 228, 1);
  border-left: 8px solid rgba(228, 228, 228, 1);
}
